import { css } from 'styled-components'

export const colors = {
  primary: '#000',
  secondary: '#000',
  headings: '#000',
  object: '#000',
  background: 'hsl(0, 0%, 97%)',
  label: 'red',
  logo: '#000',
  gray: '#8b887e', // #5b5852
}

export const hslPallet = ({
  tilesCount = 9,
  col = 3,
  hFrom = 45,
  hTo = 0,
  s = 80,
  l = 70,
} = {}) => {
  let hslColors = []
  const hueFrom = hFrom
  const hueTo = hTo
  const saturation = s
  const lightness = l
  const colorGap = (hueTo - hueFrom) / (tilesCount - 1)

  for (let i = 0; i < tilesCount; i++) {
    const parcValues = Math.round((hueFrom + i * colorGap) * 100) / 100
    hslColors[i] = `hsl(${parcValues}, ${saturation}%, ${lightness}%)`
  }
  return hslColors
}

export const nthChildBgColors = (tilesCount = 9) => {
  let nthChildCss = `/* colors\n */`

  for (let i = 0; i < tilesCount; i++) {
    nthChildCss += `
      &:nth-child(${i + 1}) {
        background-color: ${hslPallet(tilesCount)[i]};
      }\n
    `
  }

  return css`
    ${nthChildCss}
  `
}

const splitArrayInChunks = (arr, chunk_size) =>
  Array(Math.ceil(arr.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => arr.slice(begin, begin + chunk_size))

export const reverseArrayParts = ({ arr = [], col = 0 } = {}) => {
  let modiefiedArray = splitArrayInChunks(arr, col)

  for (let i = 0; i < col; i++) {
    if (i % 2 !== 0) {
      modiefiedArray[i] = modiefiedArray[i].reverse()
    }
  }

  return modiefiedArray.reduce((acc, val) => acc.concat(val), [])
}
