import styled from 'styled-components'
import { animated } from '@react-spring/web'
import {
  nthChildBgColors,
  hslPallet,
  fontSizeMedium,
  largeGap,
  smallGap,
  animateOpacity,
  minMedia,
  maxMedia,
  minHorizontalMedia,
} from '../utils'

// const blurTime = 0.3
// const blur = 30
// const transType = `ease-out`

export const TilesStyle = styled.nav`
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    overflow: hidden;
    ${maxMedia.small`
      display: flex;
      flex-wrap: wrap;
      margin: 0;
    `}
    > li {
      margin: 0;
      flex-basis: 33.333%;
      display: block;
      position: relative;
      box-sizing: border-box;
      ${(props) => nthChildBgColors(props.number)}
      ${maxMedia.small`
        margin: 0;
        flex-basis: 100%;
        padding: ${smallGap};
      `}
      &::before {
        content: '';
        display: block;
        padding-top: 100%;
        ${minHorizontalMedia`
          padding-top: 33.333vh;
        `}
        ${maxMedia.small`
          content: none;
          display: none;
          padding-top: 0;
        `}
      }
      > figure {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        ${animateOpacity()}
        ${maxMedia.small`
          z-index: -1;
        `}
        .gatsby-image-wrapper {
          width: 100% !important;
          height: 100% !important;
        }
      }
      > a {
        display: block;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        overflow: hidden;
        height: 100%;
        z-index: 10000;
        ${animateOpacity({ from: 1 })}
        ${maxMedia.small`
          display: block;
          width: auto;
          height: auto;
          transform: none;
          position: static;
          top: 0;
          left: 0;
          overflow: hidden;
          height: 100%;        `}
        span {
          margin: ${largeGap};
          display: inline-block;
          font-weight: normal !important;
          ${fontSizeMedium}
          ${maxMedia.xSmall`
            margin: ${smallGap};
          `}
          ${maxMedia.small`
            margin: 0;
            display: inherit;
            font-weight: normal;
            ${fontSizeMedium}
          `}
        }
      }
      &:hover {
        > figure {
          opacity: 1;
        }
        > a {
          ${minMedia.small`
            opacity: 0;
          `}
        }
      }
    }
  }
`

export const BlendStyles = styled(animated.li)`
  /* the fallback for IE 10, 11 and Edge. */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:after {
      content: '';
      background: transparentize(
        darken(${(props) => hslPallet()[props.number]}, 8),
        0.5
      );
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }

  @supports (-ms-ime-align: auto) {
    &:after {
      content: '';
      background: transparentize(
        darken(${(props) => hslPallet()[props.number]}, 8),
        0.5
      );
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }
  figure {
    background-color: ${(props) => hslPallet()[props.number]};
    /* mix-blend-mode: soft-light; */
    img {
      mix-blend-mode: luminosity;
    }
  }
`
