import React, { createContext, useState } from 'react'

export const SlideIdContext = createContext()

export const SlideIdProvider = ({ children }) => {
  const [slideId, setSlideId] = useState(0)
  return (
    <SlideIdContext.Provider value={{ slideId, setSlideId }}>
      {children}
    </SlideIdContext.Provider>
  )
}

export default SlideIdProvider
