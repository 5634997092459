import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Nav from './Nav'
import { LogoSVG, HeaderStyles } from '../styles/elements'

const Header = ({
  children = null,
  siteTitle = '',
  siteDescription = '',
  isHome = true,
}) => {
  const title = `
    ${siteTitle.split(' ').join('<br />')}<br />${siteDescription
    .split(' ')
    .join('<br />')}
  `
  return (
    <HeaderStyles>
      <h1>
        <Link to="/">
          <LogoSVG title={title} />
        </Link>
      </h1>
      {children && children}
      {isHome || <Nav />}
    </HeaderStyles>
  )
}

Header.propTypes = {
  children: PropTypes.element,
  isHome: PropTypes.bool.isRequired,
  siteDescription: PropTypes.string.isRequired,
  siteTitle: PropTypes.string.isRequired,
}

export default Header
