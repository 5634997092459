import { useEffect, useState } from 'react'
import debounce from 'lodash.debounce'

export const useMeasureWindowSize = () => {
  const [dimensions, setDimensions] = useState({
    height: typeof window !== 'undefined' ? window.innerHeight : 1920,
    width: typeof window !== 'undefined' ? window.innerWidth : 1080,
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 200)
    window.addEventListener('resize', debouncedHandleResize)
    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })
  return dimensions
}
