import styled from 'styled-components'
import {
  hslPallet,
  largeGap,
  smallGap,
  maxMedia,
  gridGap,
  fontSizeLarge,
} from '../utils'

export const NewsItemStyles = styled.article`
  &#news-item {
    padding: ${largeGap};
    background-color: ${hslPallet()[0]};
    ${maxMedia.xSmall`
      padding: ${smallGap};
    `};
    > div {
      display: flex;
      justify-content: space-between;
      position: relative;
      header {
        margin-right: ${gridGap};
        flex-basis: calc(33.333% - ${gridGap});
        a.back {
          display: block;
          margin-bottom: ${largeGap};
          ${fontSizeLarge};
          position: fixed;
          width: ${gridGap};
          z-index: 10;
        }
        > section {
          position: fixed;
          width: calc(33.333% - ${gridGap});
          top: ${largeGap};
          ${maxMedia.normal`
            top: 0;
            position: absolute;
          `}
          h2 {
            width: 100%;
            text-align: right;
            display: inline-block;
            span:first-child {
              font-weight: bold;
            }
            span:last-child {
              font-weight: normal;
              letter-spacing: 0.09em;
            }
          }
          .title-image {
            margin-top: 5px;
          }
        }
      }
      .body {
        flex-basis: calc(66.666% - ${gridGap} / 2);
        right: 0;
        .content {
          .text,
          .image {
            margin-bottom: ${gridGap};
            &:last-child {
              margin-bottom: ${largeGap};
            }
          }
          .text {
            width: 100%;
            p:last-child {
              margin: 0;
            }
          }
          .image {
            width: 100%;
          }
        }
      }
    }
  }
`
