import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import { decode } from 'he'
import { FooterStyles } from '../styles/elements'
// import { DE, EN } from '../../../gatsby-globals'

const NAV_QUERY = graphql`
  query NavQuery {
    wpMenu(slug: { eq: "menu" }) {
      name
      menuItems {
        items: nodes {
          title: label
          url
          id
        }
      }
    }
  }
`

const Footer = ({ international }) => {
  return (
    <FooterStyles>
      <FooterNav {...international} />
    </FooterStyles>
  )
}

export const FooterNav = ({ language, altLangPath }) => {
  const {
    wpMenu: {
      menuItems: { items },
    },
  } = useStaticQuery(NAV_QUERY)
  const contactTitle = items[0].title
  const contactPath = items[0].url

  const imprintId = items[1].id
  const imprintTitle = items[1].title.split('&').join('&<br />')
  const imprintPath = items[1].url

  return (
    <nav>
      <ul>
        <li className="contact">
          <Link
            to={contactPath}
            title={`Go to ${decode(items[0].title)}`}
            dangerouslySetInnerHTML={{
              __html: contactTitle,
            }}
          />
        </li>
        <li className="imprint-privacy-policy" key={imprintId}>
          <Link
            to={imprintPath}
            title={`Go to ${decode(items[1].title)}`}
            dangerouslySetInnerHTML={{
              __html: imprintTitle,
            }}
          />
        </li>
      </ul>
    </nav>
  )
}

// Imprint &<br /> Privacy Policy
// </Link>
export default Footer

// <a className="language"
// href={altLangPath}
// title={
//   (language === EN && `Zur deutschen Ausgabe wechseln`) ||
//   (language === DE && `Change languange to english`)
// }
// >
// {(language === EN && 'Deutsch') ||
//   (language === DE && 'English')}
// </a>
