import React from 'react'
import ReactPlayer from 'react-player'
import { AnimatedGrid } from '../grid'

// Grid JSON Values Information
// w, h, align, col, cont, columnSpan, relId, row, type, url, frameOverflow, iframe

export const GridTpVideo = ({ url, className, toggle, children }) => {
  return (
    <div className={className}>
      {children}
      <AnimatedGrid toggle={toggle}>
        <ReactPlayer width="100%" url={url} />
      </AnimatedGrid>
    </div>
  )
}
