import React from 'react'
import styled from 'styled-components'
import { animated } from '@react-spring/web'
import { smallGap, fontSizes, colors } from '../utils'

export const buttonSize = `${
  Math.round(
    ((fontSizes.medium * (fontSizes.small * 16) - 7) / (fontSizes.small * 16)) *
      100,
  ) / 100
}rem`

export const Burger = (props) => {
  const { name = `burger` } = props
  return (
    <StyledBurger id={name} {...props}>
      <input type="checkbox" id={name} />
      <label htmlFor={name}>
        <div></div>
        <div></div>
        <div></div>
      </label>
    </StyledBurger>
  )
}

const StyledBurger = styled(animated.button)`
  right: 0;
  margin-top: 5px;
  width: ${buttonSize};
  height: ${buttonSize};
  position: absolute;
  background: none;
  padding: 0;
  border: none;
  outline: inherit;
  z-index: 20000;

  input {
    display: none;
  }

  label {
    position: relative;
    width: 100%;
    height: 100%;

    display: inline-block;
    cursor: pointer;
    margin: 0;
    padding: 0;
    transition: all 0.5s;
    text-align: left;
    div,
    div:before,
    div:after {
      background: ${colors.primary};
      position: absolute;
      height: 2px;
      width: ${buttonSize};
      min-width: ${smallGap};
      transition: all 0.015s;
      margin-top: -3px;
    }
    div:first-child {
      top: -1px;
    }
    div:nth-child(2) {
      top: 5px;
    }
    div:nth-child(3) {
      top: 11px;
    }
  }

  #menu + label {
    div:first-child {
      transition: top 0.15s 0.15s, transform 0.15s;
    }
    div:nth-child(2),
    div:nth-child(3) {
      transition: top 0.15s 0.15s, transform 0.15s;
    }
  }

  &.close > #menu + label {
    div {
      top: calc(${buttonSize} / 2);
      width: calc(${buttonSize} * ${Math.sqrt(2)});
      min-width: ${smallGap};
      transition: top 0.15s transform 0.15s 0.15s;
      transition: width 0.15s, transform 0.15s 0.15s;
    }
    div:first-child {
      transform: rotate(45deg);
    }
    div:nth-child(2),
    div:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
`
