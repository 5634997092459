import React from 'react'
import { AnimatedGrid } from '../grid'
import { regexHTag, regexSpanTag } from '../../lib/regex'

// Grid JSON Values Information
// align, col, columnSpan, cont, frameOverflow, relId, row, type

const editGridString = (cont) => {
  const content = cont.replace(regexSpanTag, ``).replace(regexHTag, `$13`)
  return content
}

export const GridText = ({ cont, className, toggle, children }) => {
  return (
    <div className={className}>
      {children}
      <AnimatedGrid toggle={toggle}>
        <section
          dangerouslySetInnerHTML={{
            __html: editGridString(cont),
          }}
        />
      </AnimatedGrid>
    </div>
  )
}
