import React, { useState, useRef } from 'react'
import { useSpring, useTrail, animated, config } from '@react-spring/web'
import { graphql, useStaticQuery } from 'gatsby'
import { useMedia } from 'react-use-breakpoints'
import useDimensions from 'react-use-dimensions'
import { Link } from 'gatsby'
import { NavStyles, Burger } from '../styles/elements'
import { useOnClickOutside } from '../../hooks'
import debounce from 'lodash.debounce'
import {
  largeGapPxNum,
  maxNormalSizeCondition,
  mediumSizeCondition,
} from '../styles/utils'

const NAV_TILES_QUERY = graphql`
  query NavTilesQuery {
    wp: allWpTile(filter: { slug: { ne: "donotdelete" } }) {
      tiles: nodes {
        id
        slug
        title
      }
    }
    newsTile: wpPage(slug: { eq: "news" }) {
      id
      title
      slug
    }
  }
`

const Nav = () => {
  const [show, setShow] = useState(false)
  const {
    wp: { tiles },
    newsTile,
  } = useStaticQuery(NAV_TILES_QUERY)
  const [ref, { width: navWidth }] = useDimensions()
  const refNav = useRef()

  // Breakpoints hooks
  const isMobile = useMedia(maxNormalSizeCondition)
  const isMediumSize = useMedia(mediumSizeCondition)

  const allTiles = [newsTile]
    .concat(tiles)
    .filter((item) => item.slug !== 'donotdelete')

  const trail = useTrail(allTiles.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: show ? 1 : 0,
    from: { opacity: 0 },
  })

  // Calculating burger animation of x
  const burgerAnimation = () => {
    const xNoMove = 0
    const xRightMove = navWidth - 3 + largeGapPxNum
    const xLeftMove = -1 * navWidth - 3 - largeGapPxNum

    if (isMobile) {
      return xNoMove
    } else if (isMediumSize) {
      return xRightMove
    } else {
      return xLeftMove
    }
  }

  // Animate burger
  const burgerSpring = useSpring({
    x: show ? burgerAnimation() : 0,
    config: config.stiff,
  })

  // If click outside nav, close it!
  useOnClickOutside(refNav, () => setShow(false))

  return (
    <NavStyles show={show} ref={refNav}>
      <Burger
        name="menu"
        id="menu"
        style={{
          transform: burgerSpring.x.to((x) => `translate3d(${x}px, 0px, 0px)`),
        }}
        className={show ? 'close' : ''}
        onMouseDown={() => {
          setShow((prevShow) => !prevShow)
        }}
        onTouch={() => {
          setShow((prevShow) => !prevShow)
        }}
      />
      <div className="tiles-menu" ref={ref}>
        <section style={{ display: show ? 'initial' : 'none' }}>
          <ul>
            {trail.map((styles, index) => (
              <animated.li style={styles} key={allTiles[index].slug}>
                <Link
                  onClick={() => show && setShow(false)}
                  to={`/${allTiles[index].slug}`}
                  dangerouslySetInnerHTML={{
                    __html: `<span>${allTiles[index].title}</span>`,
                  }}
                />
              </animated.li>
            ))}
          </ul>
        </section>
      </div>
    </NavStyles>
  )
}

export default Nav
