import React from 'react'
import ReactPlayer from 'react-player'
import { AnimatedGrid } from '../grid'

// Grid JSON Values Information
// html5video:  w, h, align, col, cont, columnSpan, relId, row, type, frameOverflow, filename, controls, autoplay, imgar, mp4, mute, muteDisabled,

export const GridVideo = ({ className, toggle, children, video }) => {
  return (
    <div className={className}>
      {children}
      <AnimatedGrid toggle={toggle}>
        <ReactPlayer width="100%" url={video.publicURL} controls={true} />
      </AnimatedGrid>
    </div>
  )
}
