import styled from 'styled-components'
import { hslPallet, largeGap, smallGap, maxMedia, noselect } from '../utils'

export const TileStyles = styled.div`
  ${noselect}
  ${maxMedia.normal`
    width: 100vw;
  `}
  .tile {
    padding: ${largeGap};
    padding-bottom: 0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    ${maxMedia.normal`
      height: auto;
      min-height: calc(100vh - 234px);
      overflow: hidden;
    `}
    ${maxMedia.xSmall`
      padding: ${smallGap};
    `}
    &.prev-tile {
      background-color: ${(props) => hslPallet()[props.number - 1]};
    }
    &.actual-tile {
      background-color: ${(props) => hslPallet()[props.number]};
    }
    &.next-tile {
      background-color: ${(props) => hslPallet()[props.number + 1]};
    }
    &.last-tile {
      background-color: ${(props) => {
        return props.count ? hslPallet()[props.count] : hslPallet()[8]
      }};
    }
  }
`
