exports.shouldUpdateScroll = ({
  routerProps: {
    location: { state },
  },
  prevRouterProps: {
    location: prevLocation,
    location: { state: prevState },
  },
  pathname,
  getSavedScrollPosition,
}) => {
  const lastScrollPosition = getSavedScrollPosition(prevLocation)
  const lastPath = prevLocation.pathname.split('/').join('')
  const actualPath = pathname.split('/').join('')
  const isSwiped =
    (state && prevState && state.swiped && prevState.swiped && true) || false

  const scrollLast =
    lastPath === '' ||
    lastPath === 'contact' ||
    lastPath === 'imprint-privacy-policy'
      ? true
      : false

  const scrollActual =
    actualPath === '' ||
    actualPath === 'contact' ||
    actualPath === 'imprint-privacy-policy'
      ? true
      : false

  const scroll = isSwiped && !scrollLast && !scrollActual ? true : false

  return scroll ? lastScrollPosition : [0, 0]
}
