import React from 'react'
import { AnimatedGrid } from '../grid'

// Grid JSON Values Information
// align, col, columnSpan, cont, frameOverflow, relId, row, type

export const GridHtml = ({ cont, className, toggle, children }) => {
  return (
    <div className={className}>
      {children}
      <AnimatedGrid toggle={toggle}>
        <section
          dangerouslySetInnerHTML={{
            __html: cont,
          }}
        />
      </AnimatedGrid>
    </div>
  )
}
