import React from 'react'
import Image from '../utils/Image'
import { regexHtmlTag } from '../../lib/regex'
import PropTypes from 'prop-types'

// Grid JSON Values Information
// w, h, align, alt, cont, attid, col, columnSpan, relId, row, type
//   sizes:
//     full: height, orientation, url, width,
//     const fullUrl = `${BackendConfig.url}${full.url}`;
//     large: height, orientation, url, width,
//     const largeUrl = `${BackendConfig.url}${large.url}`;
//     medium: height, orientation, url, width,
//     const mediumUrl = `${BackendConfig.url}${medium.url}`;
//     medium_large: height, orientation, url, width,
//     const smallUrl = `${BackendConfig.url}${medium_large.url}`;
//     thumbnail: height, orientation, url, width,
//     const thumbnailUrl = `${BackendConfig.url}${thumbnail.url}`;

export const GridImage = ({
  alt = '',
  children = null,
  className = '',
  imageLink = {},
  localFile = null,
  caption = null,
}) => {
  const captionStr = caption ? caption.replace(regexHtmlTag, '') : null
  return (
    <div className={className}>
      {children}
      {localFile && (
        <>
          {imageLink ? (
            <a
              href={imageLink.url}
              target="_blank"
              rel="noopener noreferrer"
              title="Go to external Video"
            >
              <Image file={localFile} caption={caption && captionStr} />
            </a>
          ) : (
            <Image file={localFile} caption={caption && captionStr} />
          )}
        </>
      )}
    </div>
  )
}

GridImage.propTypes = {
  alt: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  imageLink: PropTypes.object,
  localFile: PropTypes.object,
}
