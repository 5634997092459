import { css } from 'styled-components'

export const breakpoints = {
  xSmall: 500,
  small: 680,
  normal: 1023,
  medium: 1050,
  large: 1080,
  xLarge: 1600,
  xxLarge: 2040,
}

export const maxNormalSizeCondition = `(max-aspect-ratio: 13/10), (max-width: ${breakpoints.normal}px)` // 1.3/1
export const minNormalSizeCondition = `(min-aspect-ratio: 13/10) and (min-width: ${breakpoints.medium}px)` // 1.3/1
export const mediumSizeCondition = `(max-aspect-ratio: 15/10), (min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large}px)` // 1.5/1
export const minHorizontalCondition = `(min-aspect-ratio: 2/1) and (min-width: ${breakpoints.medium}px) ` // 2/1
export const menuBreakCondition = `(max-aspect-ratio: 57/40) and (min-width: ${breakpoints.normal}px)` // 1.425/1
export const touchMediaCondition = `(hover: none) and (pointer: coarse)`

export const minMedia = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const maxMedia = Object.keys(breakpoints).reduce((acc, label) => {
  if (label === 'normal') {
    acc[label] = (...args) => css`
      @media ${maxNormalSizeCondition} {
        ${css(...args)}
      }
    `
  } else {
    acc[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}px) {
        ${css(...args)}
      }
    `
  }
  return acc
}, {})

export const minNormalSizeMedia = Object.keys(breakpoints).reduce(
  (acc, label) => {
    acc = (...args) => css`
      @media ${minNormalSizeCondition} {
        ${css(...args)}
      }
    `
    return acc
  },
  {},
)

export const mediumSizeMedia = Object.keys(breakpoints).reduce((acc, label) => {
  acc = (...args) => css`
    @media ${mediumSizeCondition} {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const minHorizontalMedia = Object.keys(breakpoints).reduce(
  (acc, label) => {
    acc = (...args) => css`
      @media ${minHorizontalCondition} {
        ${css(...args)}
      }
    `
    return acc
  },
  {},
)

export const menuBreakMedia = Object.keys(breakpoints).reduce((acc, label) => {
  acc = (...args) => css`
    @media ${menuBreakCondition} {
      ${css(...args)}
    }
  `
  return acc
}, {})
