import { css } from 'styled-components'

export const animateOpacity = ({
  from = 0,
  time = 0.3,
  animation = `ease-out`,
} = {}) => {
  return css`
    opacity: ${from};
    transition: opacity ${time}s ${animation};
  `
}

export const animBlurFrom = ({
  time = 0.3,
  strength = 30,
  animation = `ease-out`,
} = {}) => {
  const blurTime = time
  const blur = strength
  const transType = animation
  return css`
    filter: blur(${blur}px);
    ${`filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='${
      blur / 10
    }');`}
    -webkit-filter: blur(${blur}px);
    -moz-filter: blur(${blur}px);
    -o-filter: blur(${blur}px);
    -ms-filter: blur(${blur}px);
    transition: opacity ${blurTime}s ${transType},
      ${blurTime}s filter ${transType};
    -webkit-transition: opacity ${blurTime}s ${transType},
      ${blurTime}s -webkit-filter ${transType};
    -moz-transition: opacity ${blurTime}s ${transType},
      ${blurTime}s -moz-filter ${transType};
    -ms-transition: opacity ${blurTime}s ${transType},
      ${blurTime}s -ms-filter ${transType};
    -o-transition: opacity ${blurTime}s ${transType},
      ${blurTime}s -o-filter ${transType};
  `
}

export const animBlurTo = css`
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
  filter: blur(0px);
`
