import React, { createContext, useState } from 'react'

export const SliderContext = createContext()

export const SliderProvider = ({ children }) => {
  const [slide, setSlide] = useState(false)
  const [slideId, setSlideId] = useState(0)
  return (
    <SliderContext.Provider value={{ slide, setSlide, slideId, setSlideId }}>
      {children}
    </SliderContext.Provider>
  )
}

export default SliderProvider
