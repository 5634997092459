import { css } from 'styled-components'
import {
  colors,
  smallGap,
  largeGap,
  footerHeight,
  footerSmallHeight,
  noselect,
  maxMedia,
  minHorizontalMedia,
} from '../utils'

export const LayoutStyles = css`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  position: relative;

  ${maxMedia.normal`
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
  `}
  .left-main, .right-main {
    overflow: hidden;
    margin: 0;
    box-sizing: border-box;
    padding: 0;
    ${maxMedia.normal`
      min-height: auto;
      margin: 0;
      box-sizing: border-box;
      padding: 0;
      width: 100vw;
    `}
  }

  .left-main {
    flex-basis: calc(100vw - 100vh);
    flex-shrink: 0;
    ${minHorizontalMedia`
      flex-basis: 50vw;
    `}
    ${maxMedia.normal`
      flex-basis: 100%;
    `}
  }

  .right-main {
    box-sizing: content-box;
    ${(props) => {
      return (
        props.isPage &&
        `
        height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      `
      )
    }}
    flex-basis: 100vh;
    color: ${colors.secondary};
    ${minHorizontalMedia`
      flex-basis: 50vw;
    `}
    ${maxMedia.normal`
      height: fit-content;
      // max-height: 100vw;
      flex-basis: 100%;
      margin-bottom: ${largeGap};
      padding-bottom: ${footerHeight};
    `}
    ${maxMedia.xSmall`
      margin-bottom: ${smallGap};
      padding-bottom: ${footerSmallHeight};
    `}
    a, p, h1, h2, h3, h4, h5, span {
      color: ${colors.secondary};
    }
    a,
    p,
    span {
      font-weight: 200;
    }
    p > a {
      padding: 0;
      display: inline-block;
      position: relative;
      line-height: 0.9;
      border-bottom: 2px solid ${colors.primary};
      padding-bottom: 1px;
      vertical-align: bottom;
      white-space: nowrap;
      &:hover {
        font-weight: bold;
      }
      &:after {
        line-height: 1;
        display: block;
        content: attr(title);
        visibility: hidden;
        font-weight: bold;
        overflow: hidden;
        height: 0px;
      }
    }
    .arrow {
      position: relative;
      font-size: 2rem;
      ${noselect}
      z-index: 1000;
      a {
        display: block;
        position: absolute;
        height: 100vh;
        width: ${largeGap};
        z-index: 10100;
      }
      > div {
        z-index: 1010;
      }
      .moving,
      .not-moving {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &:after {
          content: '';
          display: inline-block;
          width: ${largeGap};
          height: ${largeGap};
          border: 2px solid;
          border-bottom: 0;
          border-left: 0;
          border-radius: 1px;
          text-indent: -9999px;
        }
      }
      .left {
        left: 0;
        &.moving {
          margin-left: 6px;
        }
        &:after {
          transform: rotate(225deg);
        }
      }
      .right {
        right: 0;
        margin-right: ${({ osInfo }) =>
          osInfo.osName === 'windows' ? 20 : 6}px;
        &.moving {
        }
        &:after {
          transform: rotate(45deg);
        }
      }
      .not-moving {
        a {
          width: 40px;
          height: 40px;
          transform: translateY(-2px);
        }
        position: fixed;
        &.right,
        &.left {
          margin: auto 6px;
        }
      }
    }
  }
`
