import React from 'react'
import { SliderProvider } from './slider'
import { SlideIdProvider } from './slideId'

const ProviderComposer = ({ contexts, children }) => {
  return contexts.reduceRight(
    (kids, parent) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children,
  )
}

export const ContextProvider = ({ children }) => {
  return (
    <ProviderComposer contexts={[<SliderProvider />, <SlideIdProvider />]}>
      {children}
    </ProviderComposer>
  )
}
