import styled from 'styled-components'
import {
  fontSizeMedium,
  largeGap,
  smallGap,
  maxMedia,
  mediumSizeMedia,
  colors,
} from '../utils'
import { buttonSize } from '../elements'

export const NavStyles = styled.nav`
  max-width: 220px;
  ${mediumSizeMedia`
    max-width: 240px;
  `}
  ${maxMedia.xSmall`
    padding: 0 !important;
  `}
  #menu {
    right: 0;
    ${mediumSizeMedia`
      left: 0;
      right: auto;
    `}
    ${maxMedia.normal`
      z-index: 10008;
      position: fixed;
      left: auto;
      right: calc(${largeGap} + 1px);
      top: calc(${largeGap} - 1px);
    `}
    ${maxMedia.xSmall`
      right: calc(${smallGap} + 1px);
      top: calc(${smallGap} - 1px);
    `}
  }

  .tiles-menu {
    a,
    span {
      color: ${colors.secondary};
    }
    ${mediumSizeMedia`
      width: calc(100% - ${largeGap} - ${buttonSize});
      `}
    ${maxMedia.normal`
      // width: auto;
      width: 50vw;
      z-index: 1000;
      position: fixed;
      right: calc(2 * ${largeGap} + ${buttonSize});
      top: ${largeGap};
      `}
    ${maxMedia.small`
      width: auto;
      z-index: 1000;
      position: fixed;
      left: ${largeGap};
      right: calc(2 * ${largeGap} + ${buttonSize});
      top: ${largeGap};
      `}
    ${maxMedia.xSmall`
      transition: background-color 300ms ease-out;
      z-index: 2000;
      top: 0;
      left: 0;
      right: 0;
      ${(props) =>
        props.show &&
        `
          width: 100vw;
          height: 100vh;
          background-color: ${colors.background}
      `};
    `}
    section {
      width: 100%;
      max-height: calc(100vh - 2 * ${largeGap});
      overflow: hidden;
      overflow: -moz-scrollbars-none;
      ${maxMedia.xSmall`
        width: auto;
        margin: 0 auto;
        margin-top: ${smallGap};
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        max-height: calc(100vh - 2 * ${smallGap});
      `}
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 5px;
        ${maxMedia.large`
          margin-top: 0;
        `}
        li {
          flex-basis: 100%;
          background-color: ${colors.background};
          width: 100%;
          height: 100%;
          z-index: 10000;
          a {
            display: block;
            width: 100%;
            height: 100%;
            ${fontSizeMedium}
            padding: 1rem 0;
            ${maxMedia.normal`
              padding: 1rem;
            `}
            &:first-child {
              padding-top: 0;
            }
          }
        }
        ${maxMedia.normal`
          margin-top: 0;
        `}
      }
    }
  }
`
