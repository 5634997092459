import styled from 'styled-components'
import {
  largeGap,
  maxMedia,
  mediumSizeMedia,
  fontSizeLarge,
  smallGap,
  minHorizontalMedia,
} from '../utils'

export const HeaderStyles = styled.header`
  width: calc(100vw - 100vh - 2 * ${largeGap});
  padding: ${largeGap};
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  ${minHorizontalMedia`
    width: calc(50vw - 2 * ${largeGap});
  `}
  ${maxMedia.xSmall`
    width: calc(100vw - 100vh - 2 * ${smallGap});
  `}
  nav {
    flex-basis: calc(100% - 180px);
    position: relative;
  }
  h1 {
    flex-basis: 180px;
    display: block;
    margin-right: ${largeGap};
  }
  ${mediumSizeMedia`
    flex-wrap: wrap;
    justify-content: baseline;
    nav {
      flex-basis: 100%;
      max-width: auto;
      top: -6px;
      margin-top: ${largeGap};
    }
    h1 {
      flex-basis: 100%;
      margin: 0;
    }
    `}
  ${maxMedia.normal`
    padding: 0;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: baseline;
    position: static;
    justify-content: space-between;
    > * {
      padding: ${largeGap};
    }
    h1 {
      flex-basis: 180px;
    }
    a.contact {
      display: block;
      align-self: flex-end;
      font-family: 'd-din', Helvetica, Tahoma, sans-serif;
      font-weight: bold;
      margin: 0;
      vertical-align: bottom;
      text-transform: uppercase;
      letter-spacing: 0.07em;
      ${fontSizeLarge};
    }
    nav {
      margin: 0;
      position: absolute;
    }
    `}
  ${maxMedia.small`
    > * {
      padding: ${smallGap};
    }
    h1 {
      padding-right: 0;
      margin-right: 0;
    }
    a.contact {
      padding-left: 0;
      margin-left: 0;
    }
  `}
`
