import React, { useState, useEffect, useContext } from 'react'
import { useSpring, animated, config } from '@react-spring/web'
import { SliderContext } from '../../global-state'

export const AnimatedGrid = ({ children, className, toggle }) => {
  const { slide } = useContext(SliderContext) || { setSlide: false }
  const [show, setShow] = useState(false)
  const animation = useSpring({
    opacity: show ? 1 : 0,
    config: config.molasses,
  })
  useEffect(() => {
    setShow(true)
  }, [show])

  return !slide ? (
    <>
      <animated.div className={className} style={animation}>
        {children}
      </animated.div>
    </>
  ) : (
    <>{children}</>
  )
}
