const DE = `de`
const EN = `en`
const LANGUAGE = process.env.GATSBY_LANG
const frontendEnURL = process.env.GATSBY_EN_FRONTEND_URL
const frontendDeURL = process.env.GATSBY_DE_FRONTEND_URL
const robots = process.env.GATSBY_ROBOTS
const apiUrl = process.env.API_URL
const graphqlApiUrl = `${apiUrl}/graphql`

module.exports = {
  DE,
  EN,
  LANGUAGE,
  robots,
  frontendEnURL,
  frontendDeURL,
  apiUrl,
  graphqlApiUrl,
}
