import React, { useState } from 'react'
import * as easings from 'd3-ease'
import { useSpring, animated } from '@react-spring/web'
import styled from 'styled-components'
import { logoPath } from './logoPath'
import {
  colors,
  smallGapRemPx,
  fontSizeLarge,
  fontSizes,
  maxMedia,
  minHorizontalMedia,
} from '../utils'

const AnimFeTurbulence = animated('feTurbulence')
const AnimFeDisplacementMap = animated('feDisplacementMap')

export const LogoSVG = ({ title }) => {
  let timeout
  const [open, toggle] = useState(false)
  const [titleStart, setTitleStart] = useState(false)

  const galaxyAnim = useSpring({
    reverse: !open,
    from: {
      scale: 0,
      opacity: 0,
      transform: `scale(0) rotate(0deg)`,
      freq: '0.03, 0.03',
    },
    to: {
      scale: open ? 75 : 0,
      opacity: open ? 1 : 0,
      transform: open ? `scale(1) rotate(360deg)` : `scale(0) rotate(0deg)`,
      freq: open ? `0, 0` : `0.03, 0.03`,
    },
    config: { duration: 1600, easing: easings.easeCubic },
  })
  const mildeAnim = useSpring({
    reverse: open && !titleStart,
    from: {
      scale: 75,
      opacity: 1,
      transform: `scale(1)`,
    },
    to: {
      scale: titleStart ? 0 : 75,
      opacity: titleStart ? 0 : 1,
      transform: titleStart ? `scale(0)` : `scale(1)`,
    },
    config: { duration: 1600, easing: easings.easeCubic },
  })

  return (
    <StyledLogo
      onMouseMove={() => {
        toggle(true)
        setTitleStart(true)
      }}
      onClick={() => {
        clearTimeout(timeout)
        toggle(true)
        timeout = setTimeout(() => {
          toggle(false)
        }, 4000)
      }}
      onMouseLeave={() => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          toggle(false)
          setTitleStart(false)
        }, 4000)
      }}
    >
      <animated.span
        style={{ transform: mildeAnim.transform, opacity: mildeAnim.opacity }}
        dangerouslySetInnerHTML={{ __html: `${title}` }}
      />
      <svg className="logo"></svg>
      <animated.svg className="logo" viewBox="0 0 50 50">
        <defs>
          <filter id="water-svg-filter">
            <AnimFeTurbulence
              type="fractalNoise"
              baseFrequency={galaxyAnim.freq}
              numOctaves="1"
              result="TURB"
              seed="8"
            />
            <AnimFeDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              in="SourceGraphic"
              in2="TURB"
              result="DISP"
              scale={galaxyAnim.scale}
            />
          </filter>
        </defs>
        <animated.rect
          style={{ opacity: galaxyAnim.opacity }}
          filter="url(#water-svg-filter)"
          id="Background"
          width="50"
          height="50"
          rx="4.645"
          fill={colors.logo}
        />
        <animated.path
          filter="url(#water-svg-filter)"
          style={{
            transform: galaxyAnim.transform,
            opacity: galaxyAnim.opacity,
          }}
          d={logoPath}
        />
      </animated.svg>
    </StyledLogo>
  )
}

const StyledLogo = styled.figure`
  position: relative;
  span,
  svg {
    display: block;
  }
  span {
    ${fontSizeLarge}
    vertical-align: text-top;
    filter: url('#water-svg-filter');
    transform-origin: calc(2em + 4px) center;
    ${minHorizontalMedia`
      transform-origin: calc(2em + .05vw) center;
    `}
  }
  svg.logo {
    position: absolute;
    top: calc(2em + 5px);
    left: calc(2em + 4px);
    transform: translate(-50%, -50%);
    width: auto;
    height: calc(4em + 5px);
    overflow: visible;
    ${minHorizontalMedia`
      top: calc(2em + .3vw);
      left: calc(2em + .05vw);
      height: calc(${fontSizes.small * 4}em + .15vw);
    `}
    /* ${maxMedia.small`
      margin-top: calc(${smallGapRemPx} / 2);
      margin-left: calc(${smallGapRemPx} / 2);
    `} */
    ${maxMedia.small`
      margin-top: 0;
      margin-left: 0;
    `}
    rect {
      background: ${colors.logo};
    }
    path {
      margin: auto;
      display: block;
      width: 100%;
      transform-origin: center;
      fill: ${colors.background};
    }
  }
`
