import styled, { css } from 'styled-components'
import {
  colors,
  maxMedia,
  gridGap,
  largeGap,
  smallGap,
  gridSmallGap,
  lineHeights,
  fontSizeMedium,
} from '../utils'
import {
  GridText,
  GridImage,
  GridVideo,
  GridTpVideo,
  GridHr,
  GridHtml,
} from '../../grid'

// Grid JSON Values Information
// image, video:
//   sizes:
//     full: height, orientation, width
//     large: height, orientation, width
//     medium: height, orientation, width
//     medium_large: height, orientation, width
//     thumbnail: height, orientation, width

// bgColor, bottomFrameMargin, colCount, frameMargin, topFrameMargin, push
export const GridStyles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(grid) => grid.colCount}, 1fr);
  grid-gap: ${gridGap};
  padding-bottom: ${largeGap};
  ${maxMedia.small`
    grid-template-columns: 100%;
  `};
  ${maxMedia.xSmall`
    grid-gap: ${gridSmallGap};
  `};
  p {
    &:last-child {
      margin-block-end: 0;
    }
  }
`

const gridItemStyles = css`
  grid-column: ${(grid) => grid.col + 1} / span ${(grid) => grid.columnSpan};
  grid-row: ${(grid) => grid.row + 1};
  transform: translateY(${(grid) => grid.offsety}rem);
  align-self: ${(grid) => {
    switch (grid.align) {
      case `top`:
        return `start`
      case `middle`:
        return `center`
      case `bottom`:
        return `end`
      default:
        return `start`
    }
  }};
  ${maxMedia.small`
    grid-column: 1 / span 1;
    grid-row: auto;
    transform: translateY(0);
  `};
`

// align, col, columnSpan, cont, row
export const GridTextStyles = styled(GridText)`
  ${gridItemStyles};
  ul {
    list-style-type: initial;
    margin-left: ${largeGap};
    margin-bottom: ${smallGap};
    li {
      list-style-type: initial;
      font-weight: 200;
      ${fontSizeMedium}
      line-height: ${lineHeights.small};
    }
  }
`

// w, h, align, alt, cont, attid, col, columnSpan, row
export const GridImageStyles = styled(GridImage)`
  ${gridItemStyles};
`

// w, h, align, col, cont, columnSpan, row, spaceabove, spacebelow, autoplay, push
export const GridVideoStyles = styled(GridVideo)`
  ${gridItemStyles};
`

// w, h, align, col, cont, columnSpan, row
export const GridTpVideoStyles = styled(GridTpVideo)`
  ${gridItemStyles};
`

// align, col, columnSpan, row
export const GridHrStyles = styled(GridHr)`
  ${gridItemStyles};
  hr {
    border: none;
    background: ${colors.secondary};
    height: 2px;
    margin-bottom: -1.25rem;
  }
`

// align, col, columnSpan, cont, row
export const GridHtmlStyles = styled(GridHtml)`
  ${gridItemStyles};
`
