import React from 'react'
import { AnimatedGrid } from '../grid'

// Grid JSON Values Information
// align, col, columnSpan, relId, row, type

export const GridHr = ({ className, toggle, children }) => {
  return (
    <div className={className}>
      {children}
      <AnimatedGrid toggle={toggle}>
        <hr />
      </AnimatedGrid>
    </div>
  )
}
