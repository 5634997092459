import styled from 'styled-components'
import {
  colors,
  smallGap,
  largeGap,
  fontSizeLarge,
  footerHeight,
  footerSmallHeight,
  maxMedia,
  mediumSizeMedia,
  minHorizontalMedia,
} from '../utils'

export const FooterStyles = styled.footer`
  width: calc(100vw - 100vh - 2 * ${largeGap});
  padding: ${largeGap};
  position: fixed;
  left: 0;
  bottom: 0;
  ${minHorizontalMedia`
    width: calc(50vw - 2 * ${largeGap});
  `}
  ${maxMedia.normal`
    position: absolute;
    padding: 0;
    width: calc(100vw - 2 * ${largeGap});
    bottom: 0;
    height: ${footerHeight};
    margin-top: ${largeGap};
    padding-left: ${largeGap};
    `}
  ${maxMedia.small`
    width: calc(100vw - 2 * ${smallGap});
    height: ${footerSmallHeight};
    padding-left: ${smallGap};
  `}
  nav {
    width: 100%;
    ul {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      li {
        white-space: nowrap;
        align-self: flex-end;
        &.contact {
          a {
            margin-right: ${smallGap};
            display: block;
            text-align: left;
            ${maxMedia.normal`
              display: inline;
            `}
            ${maxMedia.small`
              display: block;
            `}
            ${mediumSizeMedia`
              text-align: left;
            `}
            &.language {
              color: ${colors.gray};
            }
          }
        }
        &.imprint-privacy-policy {
          margin-left: auto;
          margin-right: 0;
          ${maxMedia.normal`
            margin-left: auto;
          `}
          a {
            text-align: right;
            white-space: pre-wrap;
          }
        }
        a {
          display: block;
          font-family: 'd-din', Helvetica, Tahoma, sans-serif;
          font-weight: bold;
          margin: 0;
          text-transform: uppercase;
          letter-spacing: 0.07em;
          ${fontSizeLarge};
          ${maxMedia.normal`
            width: auto;
            br {
              display: none;
            }
          `}
          ${maxMedia.small`
            br {
              display: initial;
            }
          `}
          ${maxMedia.xSmall`
            br {
              display: initial;
            }
          `}
        }
      }
    }
  }
`
