import styled from 'styled-components'
import {
  colors,
  hslPallet,
  smallGap,
  largeGap,
  maxMedia,
  gridGap,
  gridSmallGap,
  noselect,
  fontSizeMedium,
  lineHeights,
} from '../utils'

export const NewsStyles = styled.div`
  &#news {
    padding: ${largeGap};
    padding-bottom: 0;
    background-color: ${hslPallet()[0]};
    ${noselect}
    ${maxMedia.xSmall`
      padding: ${smallGap};
    `}
  article {
      margin-bottom: ${smallGap};
      padding-bottom: ${gridGap};
      border-bottom: 2px solid ${colors.primary};
      &:last-child {
        margin: 0;
        padding-bottom: ${largeGap};
        border: none;
      }
      ${maxMedia.xSmall`
        margin-bottom: ${gridSmallGap};
      `}
      header {
        h3 {
          display: inline-block;
          width: auto;
          span:first-child {
          }
          span:last-child {
            white-space: nowrap;
            font-weight: normal;
            letter-spacing: 0.09em;
          }
        }
      }
      section {
        display: flex;
        flex-wrap: nowrap;
        ${maxMedia.xSmall`
          flex-wrap: wrap;
        `}
        > figure {
          flex-basis: calc(33.333% - ${gridGap});
          margin-right: ${gridGap};
          ${maxMedia.xSmall`
            flex-basis: 100%;
            margin-right: 0;
            margin-bottom: ${smallGap};
          `}
          img {
          }
        }
        > div {
          flex-basis: calc(66.666%);
          ${maxMedia.xSmall`
            flex-basis: 100%;
            margin: 0;
          `}
          > div > ul {
            list-style-type: initial;
            margin-left: ${largeGap};
            margin-bottom: ${smallGap};
            li {
              list-style-type: initial;
              font-weight: 200;
              ${fontSizeMedium}
              line-height: ${lineHeights.small};
            }
          }
        }
      }
      .read-more {
        ${fontSizeMedium}
        width: auto;
        padding: 0;
        display: inline-block;
        position: relative;
        line-height: 0.9;
        border-bottom: 2px solid ${colors.primary};
        padding-bottom: 1px;
        vertical-align: bottom;
        white-space: nowrap;
        &:after {
          width: 0;
          height: 0;
          line-height: 1;
          display: block;
          content: attr(title);
          visibility: hidden;
          font-weight: bold;
          overflow: hidden;
        }
        &:hover {
          font-weight: bold;
          h3,
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
`
