import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { osName, isIE, isEdge } from 'react-device-detect'
import { useMedia } from 'react-use-breakpoints'
import { useSpring, animated } from '@react-spring/web'
import { ContextProvider } from '../../global-state'
import { maxNormalSizeCondition } from '../styles/utils'
import {
  frontendEnURL,
  frontendDeURL,
  LANGUAGE,
  DE,
  EN,
} from '../../../gatsby-globals'

import Header from './Header'
import Footer from './Footer'
import GlobalStyle from '../styles/Global'
import '../styles/fonts/fonts.scss'

export const META_QUERY = graphql`
  query MetaQuery {
    site {
      siteMetadata {
        ...metaFragment
      }
    }
  }
`

export const metaFragment = graphql`
  fragment metaFragment on SiteSiteMetadata {
    title
    description
    author
  }
`

const Layout = ({
  children = null,
  children: {
    props: {
      pageContext = {},
      location = {},
      location: { pathname = '' },
    },
  },
}) => {
  const {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(META_QUERY)

  const [timeoutId, setTimeoutId] = useState(false)
  const [leftArrow, setLeftArrow] = useState(false)
  const [rightArrow, setRightArrow] = useState(false)
  const [onLeftArrow, setOnLeftArrow] = useState(false)
  const [onRightArrow, setOnRightArrow] = useState(false)
  const maxWidthNormal = useMedia(maxNormalSizeCondition)

  // Check whether the contact and / or burger menu should be displayed
  const isHome = pathname.split('/').join('') === '' ? true : false
  const isContact = pathname.split('/').join('') === 'contact' ? true : false
  const isPrivacy =
    pathname.split('/').join('') === 'imprint-privacy-policy' ? true : false
  const isPage = !isHome && !isContact && !isPrivacy ? true : false

  // Internationalization
  const language = LANGUAGE || EN
  const frontUrlEn = `${frontendEnURL}${pathname}`
  const frontUrlDe = `${frontendDeURL}${pathname}`
  const international = {
    language,
    altLanguage: DE,
    defaultLangPath: language === EN ? frontUrlEn : frontUrlDe,
    altLangPath: language === EN ? frontUrlDe : frontUrlEn,
  }

  // Arrow Clicks
  const newsNext = pageContext.slug === pageContext.nextSlug ? true : false
  const newsPrev = pageContext.slug === pageContext.prevSlug ? true : false

  // Animate Config
  const animConf = { mass: 0.1, tension: 300, friction: 25 }

  const handleScroll = useCallback(() => {
    setLeftArrow(true)
    setRightArrow(true)
    setTimeoutId(
      setTimeout(() => {
        setLeftArrow(false)
        setRightArrow(false)
      }, 7000),
    )
  }, [])

  // Add scroll event-listeners for mobile view
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(timeoutId)
    }
  }, [handleScroll, timeoutId])

  const translateArrows = (y) => {
    return `translate3d(0, ${y - 20}px, 0)`
  }

  const [springProps, set] = useSpring(() => ({
    y: 0,
    config: animConf,
  }))

  // When is which arrow used?
  const moveArrows = (y) => {
    clearTimeout(timeoutId)
    set({ y })
    setLeftArrow(true)
    setRightArrow(true)
    onRightArrow && setLeftArrow(false) && setLeftArrow(true)
    onLeftArrow && setRightArrow(false) && setRightArrow(true)
    setTimeoutId(
      setTimeout(() => {
        if (!onLeftArrow && !onRightArrow) {
          setLeftArrow(false)
          setRightArrow(false)
        }
      }, 7000),
    )
  }

  const leftAnimation = useSpring({
    opacity: leftArrow ? 1 : 0,
    config: animConf,
  })

  const rightAnimation = useSpring({
    opacity: rightArrow ? 1 : 0,
    config: animConf,
  })

  return (
    <ContextProvider>
      <div>
        <GlobalStyle
          osInfo={{
            osName: osName.toLowerCase().split(' ').join(''),
            isIE,
            isEdge,
          }}
          isPage={!isPage}
        />
      </div>
      <div id="root">
        <section className="left-main">
          <Header
            siteTitle={title}
            isHome={isHome}
            location={location}
            siteDescription={description}
          >
            {maxWidthNormal && isHome ? (
              <Link className="contact" to="/contact">
                {language === `en` ? `Contact` : `Kontakt`}
              </Link>
            ) : null}
          </Header>
        </section>
        <main
          role="presentation"
          className="right-main"
          onMouseOut={() => {
            setLeftArrow(false)
            setRightArrow(false)
          }}
          onBlur={() => {
            setLeftArrow(false)
            setRightArrow(false)
          }}
          onMouseMove={(e) => moveArrows(e.clientY)}
        >
          {(!maxWidthNormal && isPage && (
            <div className="arrow">
              <Link
                className="left"
                to={newsPrev ? '/news' : `/${pageContext.prevSlug}`}
                onMouseOver={() => setOnLeftArrow(true)}
                onFocus={() => setOnLeftArrow(true)}
                onMouseOut={() => setOnLeftArrow(false)}
                onBlur={() => setOnLeftArrow(false)}
              ></Link>
              <Link
                className="right"
                to={newsNext ? '/news' : `/${pageContext.nextSlug}`}
                onMouseOver={() => setOnRightArrow(true)}
                onFocus={() => setOnRightArrow(true)}
                onMouseOut={() => setOnRightArrow(false)}
                onBlur={() => setOnRightArrow(false)}
              ></Link>
              <animated.div
                style={{
                  transform: springProps.y.to(translateArrows),
                  ...leftAnimation,
                }}
                className="moving left"
              />
              <animated.div
                style={{
                  transform: springProps.y.to(translateArrows),
                  ...rightAnimation,
                }}
                className="moving right"
              />
            </div>
          )) ||
            (maxWidthNormal && isPage && (
              <div className="arrow">
                <animated.div
                  style={{
                    ...leftAnimation,
                  }}
                  className="not-moving left"
                >
                  <Link
                    className="left-link"
                    to={newsPrev ? '/news' : `/${pageContext.prevSlug}`}
                  />
                </animated.div>
                <animated.div
                  style={{
                    ...rightAnimation,
                  }}
                  className="not-moving right"
                >
                  <Link
                    className="right-link"
                    to={newsNext ? '/news' : `/${pageContext.nextSlug}`}
                  />
                </animated.div>
              </div>
            ))}
          {React.cloneElement(children, {
            title,
            description,
            international,
          })}
        </main>
        <Footer international={international} />
      </div>
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.shape({
    props: PropTypes.shape({
      pageContext: PropTypes.object.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Layout
