import { css } from 'styled-components'
import { minHorizontalMedia } from '../utils'
// Sizes
export const fontSizes = {
  xSmall: 0.875, // rem
  small: 1, // rem
  medium: 1.25, // rem
  large: 1.3, // rem
  screenLarge: 1.8, // rem
  xLarge: 2.2, // vh
}
export const smallGapNum = 1 // rem
export const largeGapNum = 1.5 // rem
export const smallGapPxNum = smallGapNum * (fontSizes.small * 16)
export const largeGapPxNum = largeGapNum * (fontSizes.small * 16)
export const lineHeights = {
  small: `1.2`,
}

export const smallGap = `${smallGapNum}rem`
export const largeGap = `${largeGapNum}rem`
export const smallGapRemPx = `${smallGapPxNum}px` // px
export const largeGapRemPx = `${largeGapPxNum}px` // px
export const gridSmallGap = `${smallGapNum * 1.5}rem` // rem
export const gridGap = `${largeGapNum * 1.5}rem` // rem
export const mobileSmallGap = `.5rem`
export const mobileLargeGap = `1rem`
export const footerSmallHeight = `
  ${(41 + 1 * smallGapNum * (fontSizes.small * 16)) / (fontSizes.small * 16)}rem
` // rem
export const footerHeight = `
  ${(20 + 1 * largeGapNum * (fontSizes.small * 16)) / (fontSizes.small * 16)}rem
` // rem

export const fontSizeMedium = css`
  font-size: ${fontSizes.medium}rem;
  ${minHorizontalMedia`
    font-size: ${fontSizes.medium}vw;
  `}
`

export const fontSizeLarge = css`
  font-size: ${fontSizes.large}rem;
  ${minHorizontalMedia`
    font-size: ${fontSizes.medium}vw;
    letter-spacing: 0.07em;
  `}
`
