import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Image = ({ file = null, alt = '', caption = '', className = '' }) => {
  const image = getImage(file)

  return (
    <StyledImage className={className}>
      {image && <GatsbyImage image={image} alt={alt} />}
      {caption && (
        <figcaption>
          <p // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: caption,
            }}
          />
        </figcaption>
      )}
    </StyledImage>
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  caption: PropTypes.any,
  children: PropTypes.element,
  className: PropTypes.string,
  fixed: PropTypes.object,
  fluid: PropTypes.object,
  modifiers: PropTypes.string,
}

const StyledImage = styled.figure`
  img {
    object-fit: cover;
    width: 100%;
  }
`

export default Image
