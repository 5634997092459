import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import {
  colors,
  fontSizes,
  smallGap,
  lineHeights,
  fontSizeMedium,
  fontSizeLarge,
  minMedia,
  minNormalSizeMedia,
} from './utils'
import { LayoutStyles } from './elements/LayoutStyles'

// Global Styles
const GlobalStyle = createGlobalStyle`
  ${normalize()}

  html {
    overflow-x: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'roboto', Helvetica, sans-serif;
    font-weight: normal;
    font-size: ${fontSizes.small}rem;
    line-height: ${lineHeights.small};
    color: ${colors.primary};
    background-color: ${colors.background};
    height: 100%;
    ${minNormalSizeMedia`
      overflow: hidden;
    `};
  }


  *, *::before, *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  footer, header {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  

  body {
    padding: 0;
    margin: 0 auto;
    overflow-x: hidden;
    min-height: 100%;
    #___gatsby {
      overflow: hidden;
      min-height: 100vh;
    }
  }

  a, p, h1, h2, h3, h4, h5, span {
    color: ${colors.primary};
  }
  
  /* Global styles for Layout */
  #root {
    ${LayoutStyles}
  }

  p {
    ${fontSizeMedium};
    margin-block-start: 0;
    margin-block-end: ${smallGap};
  }

  h1, h2, h3, h4, h5 {
    font-family: 'd-din', Tahoma, sans-serif;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.11em;
    ${fontSizeLarge};
    margin-bottom: ${smallGap};
  }

  h3 {
    letter-spacing: 0.08em;
    font-weight: 200;
    margin-bottom: ${smallGap};
  }

  a {
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }

  ul, figure {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    margin: 0;
    padding: 0;
  }

  figure {
    margin-top: 5px;
    figcaption {
      p {
        font-size: ${fontSizes.xSmall}rem;
        margin-top: ${smallGap};
        margin-block-end: 0;
        ${minMedia.xLarge`
          font-size: ${fontSizes.small}rem;
        `};
      }
    }
  }

  h1 figure {
    margin-top: 0;
  }
`

export default GlobalStyle
